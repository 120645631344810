.dragable {
  height: 300px;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  &.over {
    border: 4px dashed gray;
  }
}
.uploadResult {
  width: 100%;
  border: 1px solid #eee;
}
