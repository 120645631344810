.ql-container {
  background-color: #fafafa;
}
.ql-editor {
  background-color: #fff;
  font-size: 18px;
  max-width: 1140px;
  line-height: 1.8;
  p {
    margin-bottom: 10px;
  }
}
