main {
  padding: 10px;
}

.ql-container {
  height: 600px;
}

.ant-input-number-input {
  text-align: right;
}

.ant-input-number-handler-wrap {
  display: none;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

h3 {
  margin: 0;
}
